import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AllModulesService } from '../../all-modules/all-modules.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(public router: Router, private allModulesService: AllModulesService) {}

  async canActivate() {
    try{
      if (!localStorage.getItem('auth_token') ) {
         this.router.navigate(["/login"]);
        return false;
      }
      return true;
    }catch(error){
      this.router.navigate(["/login"]);
        return false;
    }
    
  }
}
