import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { tap, catchError, map } from "rxjs/operators";
import { AllModulesData } from "src/assets/all-modules-data/all-modules-data";
import { id } from "src/assets/all-modules-data/id";
import { AuthService } from "../core/auth/auth.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import axios from "axios";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class AllModulesService {
  public serverurl = environment.baseUrl;
  public apiurl;
  // Headers Setup
  private token = localStorage.getItem("auth_token");

  httpOptions = {
    headers: new HttpHeaders({
      authorization: "Bearer " + this.token,
    }),
  };
  activeChat = new Subject<any>();
  constructor(
    public router: Router,
    public authService: AuthService,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  changeTheActiveChat(data) {
    this.activeChat.next(data);
  }

  async getChatHistory(data){
    let res = await this.axiosGet("/chat/getChatHistory?room="+data,{})
    return this.axiosRes(1, res.data);
  }
  async getRoomId(data){
    let res = await this.axiosPost('/chat/getRoomId',data)
    return this.axiosRes(1,res.data)
  }

  async getAllChatUsers() {
    let res = await this.axiosGet("/chat/getUsers", {});

    return this.axiosRes(1, res.data);
  }

  // Handling Errors
  private handleError(error: any) {
    return throwError(error);
  }

  // Get Method Api
  get(type): Observable<AllModulesData[]> {
    this.apiurl = `api/${type}`;

    return this.http
      .get<AllModulesData[]>(this.apiurl)
      .pipe(tap(), catchError(this.handleError));
  }

  // Post Method Api
  add(user: any, type): Observable<any> {
    this.apiurl = `api/${type}`;
    user.id = null;
    return this.http
      .post<any>(this.apiurl, user, this.httpOptions)
      .pipe(tap(), catchError(this.handleError));
  }

  // Update Method Api
  update(user: any, type): Observable<any> {
    this.apiurl = `api/${type}`;
    const url = `${this.apiurl}/${user.id}`;
    return this.http.put<any>(url, user, this.httpOptions).pipe(
      map(() => user),
      catchError(this.handleError)
    );
  }

  // Delete Method Api
  delete(id: id, type): Observable<id> {
    this.apiurl = `api/${type}`;
    const url = `${this.apiurl}/${id}`;
    return this.http
      .delete<id>(url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  public getUrl(postFix) {
    return environment.baseUrl + postFix;
  }

  //////////////////// AXIOS SERVICES ////////////////////

  public axiosCreate(args = null) {
    let headers = {
      "Content-Type": "application/json", // 'multipart/form-data'
      Authorization: "Bearer " + localStorage.getItem("auth_token"),
    };
    if (args != null) {
      headers = Object.assign(headers, args);
    }
    return axios.create({ headers: headers });
  }
  public axiosCreateFormData(args = null) {
    let headers = {
      "Content-Type": "multipart/form-data", // 'multipart/form-data'
      Authorization: "Bearer " + localStorage.getItem("auth_token"),
    };
    if (args != null) {
      headers = Object.assign(headers, args);
    }
    return axios.create({ headers: headers });
  }
  public axiosError(error) {
    console.error("AXIOS ERROR !!", error);
    if (
      typeof error.response.status != "undefined" &&
      error.response.status == 401
    ) {
      this.authService.tokenRemove();
      this.alert(0, "You are not logged in, please login to continue.");
      this.router.navigate(["/"]);
    } else if (typeof error.response.data.responseMessage != "undefined") {
      this.alert(0, error.response.data.responseMessage);
    } else if (typeof error.response.responseMessage != "undefined") {
      this.alert(0, error.response.responseMessage);
    }
  }
  public axiosRes(status, data) {
    return { status: status, data: data };
  }

  public axiosGet(postFix, params) {
    return this.axiosCreate().get(this.getUrl(postFix), { params: params });
  }
  public axiosPost(postFix, params) {
    return this.axiosCreate().post(this.getUrl(postFix), params);
  }
  public axiosPut(postFix, params) {
    return this.axiosCreate().put(this.getUrl(postFix), params);
  }
  public axiosForm(postFix, params) {
    return this.axiosCreateFormData().post(this.getUrl(postFix), params);
  }

  public axiosDelete(postFix, params) {
    return this.axiosCreate().delete(this.getUrl(postFix), { data: params });
  }
  public alert(status, message) {
    status = status == 1 ? "success" : "error";
    //this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
  }

  public async LoginApi(params: any) {
    try {
      let res = await this.axiosPost("/login", params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 2033) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async CallApi(api_url, params: any) {
    try {
      let res = await this.axiosGet("/hr/admin/" + api_url, params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async CallApiPost(api_url, params: any) {
    try {
      let res = await this.axiosPost("/hr/admin/" + api_url, params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }

      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async ListApi(api_url, params: any) {
    try {
      let res = await this.axiosGet("/hr/admin/" + api_url + "/list", params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == false) {
        this.router.navigate(["/login"]);
      } else {
        if (error.response.data.responseCode == 500) {
          this.toastr.error(error.response.data.responseMessage, "Error");
        }
        if (error.response.data.responseData.length > 0) {
          this.toastr.error(error.response.data.responseData[0].msg, "Error");
        }
        return this.axiosRes(0, error);
      }
    }
  }
  public async AddApi(api_url, params: any) {
    try {
      let res = await this.axiosPost("/hr/admin/" + api_url + "/add", params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async AddApi2(api_url, params: any) {
    try {
      let res = await this.axiosPost("/hr/admin/" + api_url, params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async Chart_time_detailApi(api_url, params: any) {
    try {
      let res = await this.axiosGet("/hr/user/" + api_url, params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async FormDataApi(api_url, params: any) {
    try {
      let res = await this.axiosPost("/hr/admin/" + api_url, params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async EditApi(api_url, id, params: any) {
    try {
      let res = await this.axiosPost(
        "/hr/admin/" + api_url + "/update/" + id,
        params
      );
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async PutEditApi(api_url, id, params: any) {
    try {
      let res = await this.axiosPut("/hr/admin/" + api_url + id, params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async DeletedApi(api_url, id) {
    try {
      let res = await this.axiosDelete(
        "/hr/admin/" + api_url + "/delete/" + id,
        {}
      );
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async StatusUpdateApi(api_url, id, params: any) {
    try {
      let res = await this.axiosPost(
        "/hr/admin/" + api_url + "/update/status/" + id,
        params
      );
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }

  //////////////////User API ///////////////////////////
  public async UserGetApi(api_url, params: any) {
    try {
      let res = await this.axiosGet("/hr/user/" + api_url, params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      console.log(error);
      if (error.response.data.status == false) {
        this.router.navigate(["/login"]);
      } else {
        if (error.response.data.responseCode == 500) {
          this.toastr.error(error.response.data.responseMessage, "Error");
        }
        if (error.response.data.responseData.length > 0) {
          this.toastr.error(error.response.data.responseData[0].msg, "Error");
        }
        return this.axiosRes(0, error);
      }
    }
  }

  public async UserPostApi(api_url, params: any) {
    try {
      let res = await this.axiosPost("/hr/user/" + api_url, params);
      return this.axiosRes(1, res.data);
    } catch (error) {
      if (error.response.data.responseCode == 500) {
        this.toastr.error(error.response.data.responseMessage, "Error");
      }
      if (error.response.data.responseData.length > 0) {
        this.toastr.error(error.response.data.responseData[0].msg, "Error");
      }
      return this.axiosRes(0, error);
    }
  }
  public async DownloadCSV(api_url) {
    let res = await this.axiosGet("/hr/admin/" + api_url, {});
    //  console.log(res.data)
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "employeelist.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
    // axios({
    //   url: environment.baseUrl + '/hr/user/' + api_url,
    //   method: 'GET',
    //   headers: {
    //     'Authorization': 'Bearer ' + this.getToken(),
    //   },
    //   responseType: 'blob', // important
    // }).then((response) => {
    //   console.log(response)
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'file.csv'); //or any other extension
    //   document.body.appendChild(link);
    //   link.click();
    // });
  }
}
