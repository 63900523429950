import { NavigationEnd, Router, Event } from '@angular/router';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class AuthService {
    constructor(private http: HttpClient, public router: Router) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (localStorage.getItem("user_role") == "admin") {

                } else {
                    if (this.router.url == "/layout/employees/employeelist") {
                        this.router.navigate(['/layout/policies/policies-main']);
                    }
                }
            }
        });
    }

    makeLogin(args) {
        localStorage.setItem('auth_token', args.auth_token);
    }

    tokenGet() {
        console.log(localStorage.getItem('auth_token'))
        return localStorage.getItem('auth_token');
    }
    tokenRemove() {
        localStorage.removeItem('auth_token');
    }
    async loadPermisions(){
       
    }
}