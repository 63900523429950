import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Bootstrap DataTable
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http'; 
import { AllModulesService } from './all-modules/all-modules.service';
import { AuthService } from './core/auth/auth.service';

// export function apiConfigFactory(): Configuration {
//   const params: ConfigurationParameters = {
//     accessToken: localStorage.getItem("auth_token")
//   }
//   return new Configuration(params);
// }

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
   // ApiModule.forRoot(apiConfigFactory),
    BrowserModule,HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataTablesModule,

    ToastrModule.forRoot(
      {
        timeOut: 1500,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      }
    ),
  ],
  providers: [ AuthService,  AllModulesService  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
