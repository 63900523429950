import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticationGuard } from "./core/auth/authentication.guard";
import { AutologinComponent } from "./login/autologin/autologin.component";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },  
  { path: "login", loadChildren: () => import(`./login/login.module`).then((m) => m.LoginModule) }, 
  { path: "error", loadChildren: () => import(`./errorpages/errorpages.module`).then((m) => m.ErrorpagesModule) },
  { path: "layout", loadChildren: () => import(`./all-modules/all-modules.module`).then((m) => m.AllModulesModule),canActivate: [AuthenticationGuard]},
  {
    path: 'admin/login/:auth_token',
    component: AutologinComponent
  },{
    path: 'employee/login/:auth_token',
    component: AutologinComponent
  },{ path: '**', redirectTo: '/error/error404'},
];

@NgModule({
  
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
