import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllModulesService } from 'src/app/all-modules/all-modules.service';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-autologin',
  templateUrl: './autologin.component.html',
  styleUrls: ['./autologin.component.css']
})
export class AutologinComponent implements OnInit {

  constructor( public authService: AuthService,public allModuleService: AllModulesService,private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.auth_token != undefined) {
      localStorage.removeItem("user_name");
      localStorage.removeItem("profileImage")
      localStorage.removeItem("user_id")
      if (this.router.url.split('/')[1] == "admin") {
        localStorage.setItem("user_role", "admin");
      } else {
        localStorage.setItem("user_role", "employee");
      }
      this.EmployeeDetail()
      this.activatedRoute.params.subscribe(params => {
        let self = this;
        if (params.auth_token) {
          self.authService.makeLogin({
            auth_token: params.auth_token
          });
          if (this.router.url.split('/')[1] == "admin") {
            self.router.navigate(['/layout/dashboard/admin']);
          } else {
            self.router.navigate(['/layout/employees/attendanceemployee']);
          }
        }
      });
    }
  }
  async EmployeeDetail() {
    let res = await this.allModuleService.CallApi("employee/get/details", {});
    if (res.status) {
      if (res.data.responseCode == 200) {
        localStorage.setItem("user_name", res.data.responseData.name);
        localStorage.setItem("user_id", res.data.responseData._id);
        localStorage.setItem("profileImage", res.data.responseData.image);
      }
    }
  }
}
